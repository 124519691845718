/*
BLUE #0E58C8
DARK BLUE #07429C
DARK GRAY #202627
RED #F82A2A
GOLD #FFCB1B
GREEN #0FB516
*/

* {
    box-sizing: border-box;
    color: #202627;
    font-family: 'Alegreya Sans SC', sans-serif;
}

button, .button {
    text-transform: capitalize;
}

.dropimage, button, .button, [type=submit] {
    margin: 0;
    padding: 2px 12px;
}

section {
    margin: 0 auto;
    max-width: 680px;
    padding-bottom: 60px;
    width: 90%;
}

.show-run select {
    border-radius: 4px 0 0 4px;
}

.fix-float {
    overflow: hidden;
}

.pull-right {
    float: right;
}

@media (max-width: 60em) {
    nav .burger~.menu>* {
        color: #202627;
    }
}

.search {
    padding-bottom: 0px;
}

.search-lockup {
    align-items: center;
    display: flex;
    justify-content: space-around;
    margin-bottom: 8px;
    width: 100%;
}

.search-bar * {
    color: #0E58C8;
    flex-grow: 1;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    width: 33%;
}

.search-bar button:first-child {
    color: #0E58C8;
    text-align: left;
}

.search-bar *:last-child {
    text-align: right;
}

.search-lockup .button.pseudo {
    color: #0E58C8;
    font-weight: bold;
}

.search-lockup input {
    border-radius: 4px 0 0 4px;
    width: 210px;
}

.search-lockup select {
    border-radius: 0 4px 4px 0;
    border-left: 0;
}


.searchBtn {
    border-radius: 0 4px 4px 0;
    height: 32px;
    margin-right: 8px;
}

.a-z-buttons {
    display: grid;
    grid-template-columns: repeat(5, 44px);
    grid-auto-rows: minmax(56px, auto);
    margin-top: 32px;
    width: 100%;
    justify-content: space-around;
}

.alpha-button {
    margin-bottom: 12px;
}

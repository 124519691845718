/****** FORM ******/

.flex {
    align-items: baseline;
}

.comic-form .flex>* {
    padding-bottom: 0;
}

.flex.one-third>*:first-child {
    width: 33%;
}

.flex.one-third>*:last-child {
    width: 67%;
}

.flex.two-thirds>*:first-child {
    width: 67%;
}

.flex.two-thirds>*:last-child {
    width: 33%;
}

.flex.three-quarters>*:first-child {
    width: 80%;
}

.flex.three-quarters>*:last-child {
    width: 20%;
}

.comic-form h2 {
    padding: 0 0 10px;
}

input, textarea, select, .flex>select {
    padding: 2px 12px;
    height: 32px;
}

input::placeholder {
    color: #ccc;
}

label, .form-label {
    font-weight: bold;
    margin-bottom: 12px;
}

label .checkable {
    font-family: sans-serif;
    font-weight: 200;
}

input, textarea, select {
    color: #2c3334;
    font-family: sans-serif;
    font-weight: 200;
}

nav .burger~.menu>* {
    color: white;
}

.hidden {
    display: none;
}

.card .modal-rows {
    margin-top: 16px;
}

.modal-rows > div:nth-child(odd) {
    background-color: aliceblue;
}

.modal-rows > div, .modal-rows h2 {
    display: flex;
    justify-content: space-between;
    padding: 4px 8px;
}

.table {
    width: 100%;
}

.tr {
    display: grid;
    grid-template-columns: 220px repeat(auto-fill, 92px) 40px;
    gap: 10px;
    grid-auto-rows: minmax(40px, auto);
}

.tr.wishlist {
    grid-template-columns: 32px 208px 1fr 1fr 1fr 1fr 40px;
}

.td, .th {
    padding: 12px 8px;
}

.thead {
    background-color: #FFCB1B;
    color: #202627;
    font-weight: 800;
    font-size: 18px;
}

.th {
    white-space: nowrap;
}

.td.title span {
    display: block;
}

.icofont-check-circled {
    color: #0FB516;
    display: inline-block;
    font-weight: bold;
    transform: translateY(1px);
}

.icofont-check-circled:before {
    color: #0FB516;
}

.icofont-arrow-up {
    color: #0E58C8;
    display: inline-block;
    transform: rotate(45deg);
}

.td:last-child, .th:last-child {
    text-align: center;
}

.th.number, .td.number {
    text-align: right;
}

.tr.toggled {
    display: none;
}

.tr:nth-child(even) {
    background: #fff;
}

.tr.even {
    background: rgba(17, 17, 17, .05);
}

.toggle-row .td {
    line-height: 18px;
    padding: 12px 8px;
}

.title-row {
    background-color: #c7e6ff;
}

.title-row.even {
    background-color: #e2f2ff;
}

.icofont-pencil-alt-5, .icofont-pencil-alt-5:before,
.icofont-circled-down, .icofont-circled-down:before {
    color: #0E58C8;
    cursor: pointer;
}

.icofont-plus-circle, .icofont-plus-circle:before,
.icofont-ui-next, .icofont-ui-next:before {
    color: #4b514c;
    cursor: pointer;
}

.icofont-ui-next:before {
    font-size: 15px;
}

.icofont-plus-circle {
    display: inline-block;
    margin: 0 8px 0 0;
    transform: translateY(1px);
}

@media (max-width: 768px) {
    .tr {
        grid-template-columns: calc(100% - 99px) 45px 32px;
    }

    .tr.wishlist {
        gap: 0;
        grid-template-columns: 32px auto 1fr 32px;
    }

    .th, .td {
        display: none;
    }

    .th.mobile, .td.mobile {
        display: table-cell;
        line-height: 20px;
    }

    .series-view .tr.wishlist {
        grid-gap: 0;
        gap: 0;
        grid-template-columns: 54px auto 1fr;
    }

    .series-view>.tr>.td:first-child {
        padding-right: 0;
    }

    .series-view .icofont-check-circled {
        margin-right: 6px;
    }
}

.comics-list-view {
    display: flex;
    flex-direction: column;
}

.comics-list-view.comics-list-loading {
    align-items: center;
    display: flex;
    height: 100vh;
    justify-content: center;
}

.comics-list-loading .loading {
    display: flex;
    justify-content: space-between;
    width: 100px;
}

.loading span {
    background-color: #FFCB1B;
    border-radius: 50%;
    height: 20px;
    margin: 0 6px;
    width: 20px;
}

/* HTML: <div class="loader"></div> */
.loading {
    width: 70px;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(circle closest-side, #f82a2a 90%, #0000);
    background:
        var(--_g) 0% 50%,
        var(--_g) 50% 50%,
        var(--_g) 100% 50%;
    background-size: calc(100%/3) 50%;
    animation: l3 1s infinite linear;
}

@keyframes l3 {
    20% {
        background-position: 0% 0%, 50% 50%, 100% 50%
    }

    40% {
        background-position: 0% 100%, 50% 0%, 100% 50%
    }

    60% {
        background-position: 0% 50%, 50% 100%, 100% 0%
    }

    80% {
        background-position: 0% 50%, 50% 50%, 100% 100%
    }
}


.title b {
    display: inline-block;
    margin-right: 4px;
    text-align: right;
    width: 20px;
}

.series-view .title {
    padding-left: 0;
}

.series-title {
    margin: 0;
    padding: 0 0 8px;
    text-decoration: underline;
}
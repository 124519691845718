nav {
    background: #f82a2a;
}

nav .brand h2 {
    color: #FFCB1B;
    font-family: 'Bangers', cursive;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 3px;
    text-shadow: -2px -1px black;
}

nav .burger {
    background: #FFCB1B;
    color: #202627;
    display: block;
    height: 28px;
    line-height: 28px;
    padding: 0px 8px;
}

.menu-open .menu {
    padding-top: 0;
}

.menu .link {
    display: block;
    padding: 0.3em 0.9em;
}

@media (max-width: 60em) {
    .menu-underlay {
        background: rgba(1,1,1,.2);
        border-radius: 0;
        bottom: 0;
        color: transparent;
        height: 100vh;
        left: 0;
        right: 0;
        top: 0;
        transition: all .5s ease;
        position: fixed;
        width: 100%;
        z-index: 1;
    }

    .menu-open .menu {
        font-size: 22px;
        padding-left: 20px;
        padding-top: 20px;
        width: 60vw;
    }
}

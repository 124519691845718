*, *:before, *:after {
    -webkit-box-sizing: border-box !important;
    -moz-box-sizing: border-box !important;
    -ms-box-sizing: border-box !important;
    box-sizing: border-box !important;
}

.modal-overlay {
    background: rgba(0, 0, 0, .2);
    bottom: 0;
    display: block;
    height: 100vh;
    left: 0;
    opacity: 1;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 99998;
}

.search-modal {
    background: white;
    border: 0;
    border-radius: 0;
    height: 100vh;
    left: 0;
    margin: 0;
    max-width: 100vw;
    min-width: 0;
    top: 0;
    position: absolute;
    width: 100vw;
}

.card-content {
    width: 100%;
}

.modal .overlay~* header, .card header, .modal .overlay~* section, .card section, .modal .overlay~*>p, .card>p {
    padding: 8px;
}

.card > article {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
}

.card header {
    border-bottom: 1px solid #dddddd;
    box-sizing: border-box;
    max-width: 100vw;
}

.card section {
    flex-grow: 1;
    margin: 0 auto;
    padding: 8px 0 6px;
}

.card footer {
    border-top: 1px solid #dddddd;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    position: absolute;
    width: 100%;
}

.search-modal footer {
    justify-content: flex-end;
}

.search-modal footer.footer-update {
    justify-content: space-between;
}

.search-modal .modal {
    background: white;
    bottom: 0;
    height: 130px;
    position: absolute;
    width: 100%;
}

.search-modal .modal footer {
    justify-content: space-between;
}

.card .close {
    color: #0E58C8;
    font-size: 60px;
    font-weight: 100;
    height: 40px;
    line-height: 40px;
    margin: 0;
    padding: 0;
    right: 0;
    top: 0;
    width: 40px;
}

.modal-content+.modal-content {
    border-top: 1px solid #dddddd;
    margin-top: 12px;
}

.modal-content .flex {
    margin-top: 8px;
}

.modal-content form .flex>* {
    margin: 0;
    padding-bottom: 0;
    padding-left: 0;
}

.modal-content form .flex>label {
    padding-left: 8px;
}

.modal-content form>fieldset.flex {
    margin-bottom: 16px;
    margin-left: -12px;
}

.create-run {
    color: #0E58C8;
    cursor: pointer;
    margin-left: 8px;
}

.search-lockup,
.show-run {
    align-items: center;
    display: flex;
    margin-bottom: 8px;
}
.toast {
    background-color: rgb(0, 170, 255);
    border-radius: 4px;
    box-shadow: 0 2px 2px 2px rgb(0 0 0 / 20%);
    color: white;
    display: block;
    font-family: sans-serif;
    font-size: 18px;
    font-weight: 300;
    height: auto;
    left: 50%;
    margin-left: -100px;
    padding: 12px;
    position: fixed;
    text-align: center;
    top: 20px;
    width: 200px;
    z-index: 999999;
}